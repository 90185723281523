import * as React from 'react';
import {useEffect, useState} from 'react';
import {useLocation, useRoutes} from 'react-router-dom';
import routes from 'routes';
import theme from 'theme';
import DateAdapter from '@mui/lab/AdapterMoment';
import CssBaseline from '@mui/material/CssBaseline';
import {ThemeProvider} from '@mui/material/styles';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {AuthProvider} from 'context/authContext';
import {StoreProvider} from 'context/storeContext';
import {ToastProvider} from 'context/toastContext';
import {client} from 'config/apolloConfig';
import {ApolloProvider} from '@apollo/client';
import {YMInitializer} from 'react-yandex-metrika';
import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';
import {ConfirmModalProvider} from 'context/confirmModalContext';

Sentry.init({
    dsn: 'https://2636e491094444a6b10ddd48afa76263@sentry.etence.org/4',
    environment: process.env.REACT_APP_NODE_ENV,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    beforeSend: (event) => {
        if (window.location.hostname === 'localhost') {
            return null;
        }
        return event;
    }
});

export default function App() {
    const content = useRoutes(routes);
    const location = useLocation();
    const [route, setRoute] = useState({
        to: location.pathname,
        from: location.pathname
    });

    useEffect(() => {
        if (location.pathname !== route.to) {
            localStorage.clear();
        }
        setRoute((prev) => ({to: location.pathname, from: prev.to}));
    }, [location.pathname]);

    return (
        <ApolloProvider client={client}>
            <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={DateAdapter}>
                    <AuthProvider>
                        <StoreProvider>
                            <ConfirmModalProvider>
                                <ToastProvider>
                                    {process.env.REACT_APP_NODE_ENV === 'staging' && (
                                        <YMInitializer
                                            accounts={[84205264]}
                                            options={{
                                                clickmap: true,
                                                trackLinks: true,
                                                accurateTrackBounce: true,
                                                webvisor: true
                                            }}
                                        />
                                    )}
                                    <CssBaseline />
                                    {content}
                                </ToastProvider>
                            </ConfirmModalProvider>
                        </StoreProvider>
                    </AuthProvider>
                </LocalizationProvider>
            </ThemeProvider>
        </ApolloProvider>
    );
}
