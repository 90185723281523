import {gql} from '@apollo/client';

export const NOTIFICATION_SUBSCRIPTION = gql`
    subscription onNotificationAdded {
        notificationAdded {
            id
            isRead
            message
            targetType
            processType
            userId
            notificationType
            source
            createdAt
            updatedAt
        }
    }
`;
