import {gql} from '@apollo/client';

export const GET_ALL_PARTNER_NOTIFICATIONS = gql`
    query partnerNotifications($cursor: String, $order: [NotificationSortInput!], $where: NotificationFilterInput) {
        partnerNotifications(after: $cursor, where: $where, order: $order) {
            edges {
                node {
                    id
                    isRead
                    message
                    targetType
                    processType
                    userId
                    notificationType
                    source
                    createdAt
                    updatedAt
                }
            }
            totalCount
            pageInfo {
                endCursor
                hasNextPage
            }
        }
    }
`;
