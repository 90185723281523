export const RIGHTS_ENUM = {
    UPDATE_USER: 'UPDATE_USER',
    UPDATE_USER_STATUS: 'UPDATE_USER_STATUS',
    BLOCK_PARTNER: 'BLOCK_PARTNER',
    UPDATE_PARTNER: 'UPDATE_PARTNER',
    UPDATE_PARTNER_ACTIVE_STATUS: 'UPDATE_PARTNER_ACTIVE_STATUS',
    READ_USER: 'READ_USER',
    READ_USER_LIST: 'READ_USER_LIST',
    READ_PARTNER: 'READ_PARTNER',
    READ_PARTNER_LIST: 'READ_PARTNER_LIST',
    READ_SERVICE_REQUEST_TSS_LIST: 'READ_SERVICE_REQUEST_TSS_LIST',
    READ_SERVICE_REQUEST_MANAGER_LIST: 'READ_SERVICE_REQUEST_MANAGER_LIST',
    READ_SERVICE_REQUEST_CI_LIST: 'READ_SERVICE_REQUEST_CI_LIST',
    READ_SERVICE_REQUEST_VENDOR_LIST: 'READ_SERVICE_REQUEST_VENDOR_LIST',
    READ_SERVICE_REQUEST_TSS: 'READ_SERVICE_REQUEST_TSS',
    READ_SERVICE_REQUEST_MANAGER: 'READ_SERVICE_REQUEST_MANAGER',
    READ_SERVICE_REQUEST_CI: 'READ_SERVICE_REQUEST_CI',
    READ_SERVICE_REQUEST_VENDOR: 'READ_SERVICE_REQUEST_VENDOR',
    DOWNLOAD_SERVICE_REQUEST_LIST: 'DOWNLOAD_SERVICE_REQUEST_LIST',
    READ_PARTNER_NOTIFICATION_LIST: 'READ_PARTNER_NOTIFICATION_LIST',
    READ_USER_NOTIFICATION_LIST: 'READ_USER_NOTIFICATION_LIST',
    DOWNLOAD_USER_LIST: 'DOWNLOAD_USER_LIST',
    DOWNLOAD_PARTNER_LIST: 'DOWNLOAD_PARTNER_LIST',
    CREATE_REGISTRATION_OFFICE: 'CREATE_REGISTRATION_OFFICE'
};

export const ROLES_ENUM = {
    MANAGER: 'MANAGER',
    MANAGER_SUPERVISOR: 'MANAGER_SUPERVISOR',
    MANAGER_CI_SUPERVISOR: 'MANAGER_CI_SUPERVISOR',
    CI: 'CI',
    CI_SUPERVISOR: 'CI_SUPERVISOR',
    TSS: 'TSS',
    TSS_SUPERVISOR: 'TSS_SUPERVISOR',
    VENDOR: 'VENDOR',
    ADMIN: 'ADMIN',
    MANAGER_CI: 'MANAGER_CI',
    SUPERUSER: 'SUPERUSER'
};
