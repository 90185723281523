import React, {createContext, useEffect} from 'react';
import {useQuery} from '@apollo/client';
import {GET_ME} from 'graphql/getMe';
import {useLocation, useNavigate} from 'react-router-dom';
import {initialRedirect} from 'helpers';

export const AuthContext = createContext({});

export const AuthProvider = ({children}) => {
    const navigate = useNavigate();
    const location = useLocation();

    const {data, loading, error, refetch} = useQuery(GET_ME);
    const me = data?.me;
    const permissions = me?.currentRole?.permissions.map((p) => p.name) || [];

    const hasPermission = (permission) => permissions.includes(permission);

    const checkAssigneeIsMe = (request) => request.currentAssignment?.user?.id === me.id;

    useEffect(() => {
        if (me) {
            if (!me.isContactDataActual) {
                navigate('/awaiting-actualization');
            } else if (!me?.currentRole) {
                navigate('/awaiting-registration');
            }
        }
        // TODO: бесконечный вызов useEffect из-за location в deps
    }, [data, error, location.pathname]);

    useEffect(() => {
        if (location.pathname === '/' && me?.currentRole) navigate(initialRedirect(hasPermission));
    }, [loading]);

    return (
        <AuthContext.Provider value={{me, hasPermission, loading, error, refetch, checkAssigneeIsMe}}>
            {children}
        </AuthContext.Provider>
    );
};
