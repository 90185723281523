import React, {createContext, useEffect, useState} from 'react';
import {Badge, IconButton, Menu} from '@mui/material';
import {Notifications} from '@mui/icons-material';
import NotificationTabs from '../NotificationTabs';
import {useQuery, useSubscription} from '@apollo/client';
import {GET_ALL_NOTIFICATIONS} from 'graphql/notification/query/allNotifications';
import {NOTIFICATION_SUBSCRIPTION} from 'graphql/notification/notificationAdded';
import {GOALS_ENUM, reachGoal} from 'helpers';

export const NotificationContext = createContext({});

const NotificationsMenu = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const {data: subscriptionData} = useSubscription(NOTIFICATION_SUBSCRIPTION);
    const {data: allNotificationsData, refetch} = useQuery(GET_ALL_NOTIFICATIONS);

    useEffect(() => {
        refetch();
    }, [subscriptionData]);

    const handleOpenMenu = (e) => {
        reachGoal(GOALS_ENUM.NOTIFICATIONS);
        setAnchorEl(e.currentTarget);
    };

    const handleCloseMenu = () => setAnchorEl(null);

    return (
        <NotificationContext.Provider value={{subscriptionData}}>
            <Badge
                badgeContent={allNotificationsData?.allNotifications?.edges
                    .map((i) => i.node)
                    .reduce((acc, curr) => (curr.isRead ? acc : acc + 1), 0)}
                color='error'
                componentsProps={{badge: {sx: {top: 4, right: 4}}}}>
                <IconButton color='inherit' onClick={handleOpenMenu}>
                    <Notifications />
                </IconButton>
            </Badge>
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                transformOrigin={{vertical: 'top', horizontal: 'left'}}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}>
                <NotificationTabs />
            </Menu>
        </NotificationContext.Provider>
    );
};

export default NotificationsMenu;
