import {Box, Stack, Typography} from '@mui/material';
import React, {useContext} from 'react';
import moment from 'moment';
import {useMutation} from '@apollo/client';
import {MARK_AS_READ} from 'graphql/notification/mutation/markAsRead';
import {NOTIFICATION_TYPE, TARGET_TYPE_ENUM} from '../constants';
import {initialRedirect} from 'helpers';
import {AuthContext} from 'context/authContext';

const NotificationCard = ({notification, value}) => {
    const [markAsRead] = useMutation(MARK_AS_READ);
    const {hasPermission} = useContext(AuthContext);

    const readNotification = () => {
        if (!notification?.isRead) markAsRead({variables: {notificationId: notification.id}});
    };

    const handleNavigate = () => {
        switch (value) {
            case TARGET_TYPE_ENUM.SERVICE_REQUEST:
                return notification.notificationType === 'MASS_TRANSFER'
                    ? window.open(`/manager-requests`, '_blank')
                    : window.open(`${initialRedirect(hasPermission)}/${notification.source}`, '_blank');
            case TARGET_TYPE_ENUM.PARTNER:
                return window.open(`/admin/partners/${notification.source}`, '_blank');
            case TARGET_TYPE_ENUM.USER:
                return window.open(`/manager-requests`, '_blank');
        }
    };

    return (
        <Box p={1.5} mb={1} sx={{backgroundColor: !notification?.isRead && '#F0FCF9'}} onMouseEnter={readNotification}>
            {notification?.notificationType && (
                <Typography variant='h6' color={'primary'} mb={1}>
                    {NOTIFICATION_TYPE[notification?.notificationType]}
                </Typography>
            )}
            <Typography mb={1}>{notification?.message}</Typography>
            <Stack justifyContent={'space-between'}>
                <Typography color={'grey.500'}>{moment(notification?.createdAt).format('DD.MM.YY HH:mm')}</Typography>
                <Typography
                    sx={{textDecoration: 'underline', cursor: 'pointer'}}
                    color={'primary'}
                    onClick={handleNavigate}>
                    Подробнее
                </Typography>
            </Stack>
        </Box>
    );
};

export default NotificationCard;
