import {gql} from '@apollo/client';

export const GET_ME = gql`
    query me {
        me {
            id
            firstName
            secondName
            lastName
            partnerId
            isContactDataActual
            shortDisplayName
            email
            currentRole {
                id
                name
                displayName
                permissions {
                    id
                    name
                }
            }
        }
    }
`;
