import {Box, Button, Stack, Typography} from '@mui/material';
import awaitingRegistration from 'assets/images/awaiting-registration.svg';
import {AuthContext} from 'context/authContext';
import {initialRedirect} from 'helpers';
import React, {useContext, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import ServicePageLayout from 'layouts/ServicePageLayout';

const AwaitingRegistration = () => {
    const {hasPermission, refetch, me} = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (me?.currentRole) {
            navigate(initialRedirect(hasPermission));
        }
    }, [me]);

    const redirect = () => {
        refetch();
    };

    return (
        <ServicePageLayout>
            <Stack direction={'column'} justifyContent='center' spacing={3} height='100%' textAlign={'center'}>
                <Box component='img' src={awaitingRegistration} />
                <Typography variant='h4'>Дождитесь окончания процесса регистрации</Typography>
                <Typography>
                    Мы проведём проверки и настроим ваш аккаунт.
                    <br /> Обычно проверка занимает около 3 часов,
                    <br /> максимальное время 48 часов.
                </Typography>
                <Button variant='contained' onClick={redirect}>
                    Обновить
                </Button>
            </Stack>
        </ServicePageLayout>
    );
};

export default AwaitingRegistration;
