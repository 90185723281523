import React from 'react';
import {Box, Typography} from '@mui/material';
import img from 'assets/images/empty-search.svg';

const EmptySearchScreen = ({visible, children}) => {
    return (
        <>
            {visible ? (
                <Box py={8} textAlign={'center'}>
                    <Typography color={'grey.500'} pb={5}>
                        По вашему запросу ничего не найдено.
                        <br />
                        Попробуйте изменить параметры поиска.
                    </Typography>
                    <img src={img} alt={'empty-search'} height={220} />
                </Box>
            ) : (
                children
            )}
        </>
    );
};

export default EmptySearchScreen;
