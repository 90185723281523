export default {
    primary: {
        main: '#00B07E',
        dark: '#009E71',
        contrastText: 'white'
    },
    text: {
        primary: '#072D57',
        main: '#072D57',
        contrastText: '#fff'
    },
    secondary: {
        main: '#00BDB2'
    },
    grey: {
        900: '#072D57',
        800: '#1D3F66',
        700: '#557192',
        600: '#778DA8',
        500: '#99A9BA',
        400: '#B7C2CE',
        300: '#DDE2E8',
        200: '#EBEEF1',
        100: '#F0F4F7'
    },
    success: {
        main: '#00875A',
        600: '#4DAB8C',
        100: '#E6F3EF'
    },
    warning: {
        main: '#F98700',
        600: '#FBAB4D',
        100: '#FEF3E6'
    },
    error: {
        main: '#F24646',
        600: '#F67E7E',
        100: '#FEEDED'
    },
    errorLight: {
        main: '#FEEDED',
        dark: '#FCDADA',
        contrastText: '#F24646'
    },
    neutral: {
        main: '#F0F4F7',
        dark: '#EBEEF1'
    },
    bg: {
        active: '#CAF1E6',
        primary: '#E3F8F2',
        elements: '#FAFBFC',
        hover: '#EEF1F4'
    },
    cancel: {
        main: '#778DA8'
    },
    divider: '#DDE2E8'
};
