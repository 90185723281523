import {gql} from '@apollo/client';

export const GET_ALL_REQUEST_NOTIFICATIONS = gql`
    query serviceRequestNotifications(
        $cursor: String
        $order: [NotificationSortInput!]
        $where: NotificationFilterInput
    ) {
        serviceRequestNotifications(after: $cursor, where: $where, order: $order) {
            edges {
                node {
                    id
                    isRead
                    message
                    targetType
                    processType
                    userId
                    notificationType
                    source
                    createdAt
                    updatedAt
                }
            }
            totalCount
            pageInfo {
                endCursor
                hasNextPage
            }
        }
    }
`;
