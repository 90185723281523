import {gql} from '@apollo/client';

export const GET_ALL_NOTIFICATIONS = gql`
    query allNotifications($cursor: String, $order: [NotificationSortInput!], $where: NotificationFilterInput) {
        allNotifications(after: $cursor, where: $where, order: $order) {
            edges {
                node {
                    id
                    isRead
                }
            }
        }
    }
`;
