import React, {useContext, useState} from 'react';
import {AppBar, Box, Menu, MenuItem, Stack, Tab, Tabs, Toolbar, Typography} from '@mui/material';
import {KeyboardArrowDown} from '@mui/icons-material';
import logo from 'assets/images/logo.svg';
import {Link, useLocation} from 'react-router-dom';
import {AuthContext} from 'context/authContext';
import {RIGHTS_ENUM} from 'constants/rights';
import styled from '@emotion/styled';
import AvatarMenu from './AvatarMenu';
import NotificationsMenu from './NotificationsMenu';

const REQUESTS_ROUTES = [
    {
        label: 'Партнер',
        to: 'manager-requests',
        permission: RIGHTS_ENUM.READ_SERVICE_REQUEST_MANAGER_LIST
    },
    {
        label: 'Центр идентификации',
        to: 'ci-requests',
        permission: RIGHTS_ENUM.READ_SERVICE_REQUEST_CI_LIST
    },
    {
        label: 'Техническая поддержка',
        to: 'tss-requests',
        permission: RIGHTS_ENUM.READ_SERVICE_REQUEST_TSS_LIST
    },
    {
        label: 'Вендор',
        to: 'vendor-requests',
        permission: RIGHTS_ENUM.READ_SERVICE_REQUEST_VENDOR_LIST
    }
];

const SERVICE_PAGES = ['no-data', 'awaiting-actualization', 'awaiting-registration', 'block'];

const NavBar = () => {
    const location = useLocation();
    const {me, hasPermission, error} = useContext(AuthContext);

    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpenMenu = (e) => setAnchorEl(e.currentTarget);
    const handleCloseMenu = () => setAnchorEl(null);

    const allowedRoutes = REQUESTS_ROUTES.filter((route) => hasPermission(route.permission));

    const tab = () => {
        if (allowedRoutes.some((i) => location.pathname.includes(i.to))) {
            return 0;
        }
        if (location.pathname.includes('/admin')) {
            return 1;
        }
    };

    return (
        <Box sx={{flexGrow: 1}}>
            <AppBar
                position='static'
                sx={{
                    backgroundColor: 'white',
                    color: 'text.primary',
                    boxShadow: 'none',
                    borderBottom: 1,
                    borderColor: 'grey.300'
                }}>
                <Toolbar disableGutters sx={{'@media all': {minHeight: 56}}}>
                    <Box component='img' src={logo} sx={{mr: 1}} />
                    <Link to='/'>
                        <Typography variant='h5' noWrap component='div' sx={{fontWeight: 400}}>
                            Кабинет Партнёра
                        </Typography>
                    </Link>
                    <Tabs value={tab()} sx={{ml: 4}}>
                        {allowedRoutes.length === 1 && (
                            <StyledTab component={Link} label={'Заявки'} to={allowedRoutes[0].to} />
                        )}
                        {allowedRoutes.length > 1 && (
                            <StyledTab
                                label={'Заявки'}
                                onClick={handleOpenMenu}
                                icon={<KeyboardArrowDown />}
                                iconPosition={'end'}
                            />
                        )}
                        {(hasPermission(RIGHTS_ENUM.READ_PARTNER_LIST) ||
                            hasPermission(RIGHTS_ENUM.READ_USER_LIST)) && (
                            <Tab component={Link} label={'Администрирование'} to={'/admin'} />
                        )}
                    </Tabs>
                    <Box sx={{flexGrow: 1}} />
                    <Stack alignItems={'center'}>
                        {!SERVICE_PAGES.some((page) => location.pathname.includes(page)) && <NotificationsMenu />}
                        {/* <IconButton color='inherit'>
                            <Help />
                        </IconButton> */}
                        <Stack alignItems={'center'} spacing={1.5} ml={4}>
                            <Box textAlign='center'>
                                <Typography variant='body2' fontWeight={700} mb={0.2}>
                                    {me?.firstName} {me?.lastName}
                                </Typography>
                                <Typography variant='greyBody2'>{me?.email}</Typography>
                            </Box>
                            <AvatarMenu />
                        </Stack>
                    </Stack>
                </Toolbar>
            </AppBar>

            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                transformOrigin={{vertical: 'top', horizontal: 'left'}}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}>
                {allowedRoutes.map((item, index) => (
                    <MenuItem
                        key={`route-${index}`}
                        component={Link}
                        to={item.to}
                        onClick={handleCloseMenu}
                        selected={location.pathname.includes(item.to)}>
                        {item.label}
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
};

const StyledTab = styled(Tab)(({theme}) => ({
    minHeight: 56,
    '& > .MuiTab-iconWrapper': {
        marginLeft: theme.spacing(0.5)
    }
}));

export default NavBar;
