import {Divider, Stack, Typography} from '@mui/material';
import React from 'react';
import permissionDenied from 'assets/images/permission-denied.svg';
import ServicePageLayout from 'layouts/ServicePageLayout';
import CopyWrapper from '../CopyWrapper';
import {MailIcon, PhoneIcon} from 'assets/icons';
import {formatPhone} from 'helpers';

const RequestPartialAccessDenied = ({extensions}) => {
    return (
        <ServicePageLayout>
            <Stack direction={'column'} justifyContent='center' height='100%' textAlign={'center'}>
                <img src={permissionDenied} alt={'lock'} />
                <Typography variant={'h4'} mb={2}>
                    Доступ к заявке запрещен
                </Typography>
                <Typography>
                    Для получения консультации по состоянию заявки обратитесь в обслуживающую абонента компанию
                </Typography>
                <Divider sx={{my: 3}} flexItem />
                <Typography variant={'h6'} mb={1}>
                    {extensions.Name}
                </Typography>
                <Stack spacing={2} mb={2}>
                    {extensions.Inn && <Typography variant='greyBody2'>{`ИНН:  ${extensions.Inn}`}</Typography>}
                    {extensions.Kpp && <Typography variant='greyBody2'>{`КПП:  ${extensions.Kpp}`}</Typography>}
                </Stack>
                <Stack spacing={4} mb={2}>
                    {extensions.PhoneNumber && (
                        <Stack spacing={1}>
                            <MailIcon />
                            <CopyWrapper copyValue={formatPhone(extensions.PhoneNumber)}>
                                <Typography>{formatPhone(extensions.PhoneNumber)}</Typography>
                            </CopyWrapper>
                        </Stack>
                    )}
                    {extensions.Email && (
                        <Stack spacing={1}>
                            <PhoneIcon />
                            <CopyWrapper copyValue={extensions.Email}>
                                <Typography>{extensions.Email}</Typography>
                            </CopyWrapper>
                        </Stack>
                    )}
                </Stack>
            </Stack>
        </ServicePageLayout>
    );
};

export default RequestPartialAccessDenied;
