import React, {useContext, useEffect} from 'react';
import {useMutation, useQuery} from '@apollo/client';
import styled from '@emotion/styled';
import {TabPanel} from '@mui/lab';
import {Typography} from '@mui/material';
import NotificationCard from './NotificationCard';
import {CircularLoading} from 'components/loading';
import {MARK_ALL_AS_READ} from 'graphql/notification/mutation/markAllAsRead';
import {NotificationContext} from '../../NavBar/NotificationsMenu';
import {NOTIFICATION_TYPES} from 'constants/notifications';

const NotificationTabPanel = ({value, onlyUnread}) => {
    const {data: notificationData, refetch, loading, error} = useQuery(NOTIFICATION_TYPES[value]?.request);
    const [markAllAsRead] = useMutation(MARK_ALL_AS_READ);
    const notifications = notificationData
        ? notificationData[NOTIFICATION_TYPES[value]?.field]?.edges
              ?.map((edge) => edge.node)
              .filter((i) => (onlyUnread ? !i.isRead : true))
        : [];
    const {subscriptionData} = useContext(NotificationContext);

    useEffect(() => {
        refetch();
    }, [subscriptionData]);

    // const onLoadMore = () => {
    //     fetchMore({
    //         variables: {
    //             where: {targetType: {eq: value}, isRead: onlyUnread ? {eq: false} : null},
    //             cursor: pageInfo.endCursor
    //         }
    //     });
    // };

    const markAllNotifications = () => {
        markAllAsRead({
            variables: {
                targetType: value
            }
        });
    };

    return (
        <StyledTabPanel value={value} id={'menu'}>
            <CircularLoading loading={loading || error}>
                {/*<InfiniteScroll*/}
                {/*    dataLength={notifications.length || 0} //This is important field to render the next data*/}
                {/*    next={onLoadMore}*/}
                {/*    hasMore={pageInfo.hasNextPage}*/}
                {/*    loader={*/}
                {/*        <Box textAlign={'center'} pt={2}>*/}
                {/*            <CircularProgress />*/}
                {/*        </Box>*/}
                {/*    }*/}
                {/*    style={{overflowY: 'hidden'}}*/}
                {/*    scrollThreshold={'20px'}*/}
                {/*    scrollableTarget={'menu'}>*/}
                <Typography
                    sx={{textDecoration: 'underline', cursor: 'pointer'}}
                    color={'grey.500'}
                    my={1}
                    onClick={markAllNotifications}>
                    Отметить все как прочитанные
                </Typography>
                {!!notifications.length ? (
                    notifications.map((notification, key) => (
                        <NotificationCard notification={notification} key={key} value={value} />
                    ))
                ) : (
                    <Typography textAlign={'center'} mt={2} p={1.5}>
                        Уведомлений нет
                    </Typography>
                )}
                {/*</InfiniteScroll>*/}
            </CircularLoading>
        </StyledTabPanel>
    );
};

const StyledTabPanel = styled(TabPanel)(({theme}) => ({
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1),
    maxHeight: 380,
    width: 450,
    overflowY: 'auto',
    '&::-webkit-scrollbar': {width: 4},
    '&::-webkit-scrollbar-thumb': {width: 4, borderRadius: 4, backgroundColor: theme.palette.grey[400]}
}));

export default NotificationTabPanel;
