import {Stack, Typography} from '@mui/material';
import React from 'react';
import permissionDenied from 'assets/images/permission-denied.svg';
import ServicePageLayout from 'layouts/ServicePageLayout';

const RequestAccessDenied = () => {
    return (
        <ServicePageLayout>
            <Stack direction={'column'} justifyContent='center' height='100%' textAlign={'center'}>
                <img src={permissionDenied} alt={'lock'} />
                <Typography variant={'h4'} mb={2}>
                    Доступ к заявке запрещен
                </Typography>
                <Typography>Недостаточно прав для просмотра заявки</Typography>
            </Stack>
        </ServicePageLayout>
    );
};

export default RequestAccessDenied;
