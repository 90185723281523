import {Stack, Typography} from '@mui/material';
import React, {useContext, useEffect} from 'react';
import permissionDenied from 'assets/images/permission-denied.svg';
import {AuthContext} from 'context/authContext';
import {useNavigate} from 'react-router-dom';
import {initialRedirect} from 'helpers';
import ServicePageLayout from 'layouts/ServicePageLayout';

const BlockScreen = () => {
    const {hasPermission, me} = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (me) {
            navigate(initialRedirect(hasPermission));
        }
    }, [me]);

    return (
        <ServicePageLayout>
            <Stack direction={'column'} justifyContent='center' height='100%' textAlign={'center'}>
                <img src={permissionDenied} alt={'lock'} />
                <Typography variant={'h4'} mb={2}>
                    Ваш аккаунт отключен
                </Typography>
                <Typography>Обратитесь в службу поддержки</Typography>
                <Typography sx={{textDecoration: 'underline'}}>
                    <a href='mailto:astral@astral.ru'>partners@astral.ru</a>
                </Typography>
            </Stack>
        </ServicePageLayout>
    );
};

export default BlockScreen;
