import * as React from 'react';
import ReactDOM from 'react-dom';
import App from 'App';
import 'assets/styles/styles.css';
import 'moment/locale/ru';
import 'nprogress/nprogress.css';
import {BrowserRouter as Router} from 'react-router-dom';
import moment from 'moment';

moment.updateLocale('ru', {
    months: [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь'
    ]
});

ReactDOM.render(
    <Router>
        <App />
    </Router>,
    document.getElementById('root')
);
