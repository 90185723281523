export default {
    styleOverrides: {
        root: ({theme}) => ({
            backgroundColor: theme.palette.bg.elements,
            '&:hover:not(.Mui-focused)': {
                backgroundColor: theme.palette.bg.hover
            },
            '&:hover.Mui-disabled': {
                backgroundColor: theme.palette.bg.elements
            },
            '&.Mui-disabled fieldset': {
                borderColor: `${theme.palette.divider} !important`
            },
            '& fieldset': {
                borderColor: theme.palette.divider
            },
            '&:hover:not(.Mui-focused, .Mui-error) fieldset': {
                borderColor: theme.palette.divider
            },
            '& textarea::placeholder': {
                color: theme.palette.grey[600],
                opacity: 1
            },
            '& textarea.Mui-disabled': {
                WebkitTextFillColor: theme.palette.grey[400]
            },
            '& .MuiSelect-select.Mui-disabled': {
                WebkitTextFillColor: theme.palette.grey[400]
            },
            input: {
                '&.Mui-disabled': {
                    WebkitTextFillColor: theme.palette.grey[400]
                },
                '&::placeholder': {
                    color: theme.palette.grey[600],
                    opacity: 1
                }
            }
        })
    },
    defaultProps: {
        size: 'small'
    }
};
