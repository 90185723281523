import React, {useEffect} from 'react';
import NProgress from 'nprogress';
import {Box} from '@mui/material';

const ProgressLoading = ({loading = false, children}) => {
    useEffect(() => {
        NProgress.configure({showSpinner: false});
        if (loading) {
            NProgress.start();
        } else NProgress.done();
        return () => NProgress.done();
    }, [loading]);

    return (
        <>
            {loading ? (
                <Box
                    sx={{
                        backgroundColor: 'background.paper',
                        minHeight: '100%'
                    }}
                />
            ) : (
                children
            )}
        </>
    );
};

export default ProgressLoading;
