export default {
    fontFamily: ['Ubuntu', 'Roboto'].join(','),
    h1: {
        fontWeight: 500,
        fontSize: '2.1875rem', //35
        lineHeight: '40px'
    },
    h2: {
        fontWeight: 500,
        fontSize: '1.8125rem', //29
        lineHeight: '32px'
    },
    h3: {
        fontWeight: 500,
        fontSize: '1.5rem', //24
        lineHeight: '28px'
    },
    h4: {
        fontWeight: 500,
        fontSize: '1.25rem', //20
        lineHeight: '24px'
    },
    h5: {
        fontWeight: 500,
        fontSize: '1rem', //16
        lineHeight: '24px'
    },
    h6: {
        fontWeight: 500,
        fontSize: '0.875rem', //14
        lineHeight: '16px'
    },
    h7: {
        fontWeight: 500,
        fontSize: '0.75rem', //12
        lineHeight: '16px'
    },
    subtitle1: {
        lineHeight: '24px'
    },
    body1: {
        fontWeight: 400,
        fontSize: '0.875rem', //14
        lineHeight: '20px'
    },
    body2: {
        fontWeight: 400,
        fontSize: '0.75rem', //12
        lineHeight: '16px'
    },
    button: {
        fontWeight: 500,
        textTransform: 'none'
    },
    greyBody1: {
        //body1
        fontWeight: 400,
        fontSize: '0.875rem', //14
        lineHeight: '20px',
        //grey.600
        color: '#778DA8'
    },
    greyBody2: {
        //body2
        fontWeight: 400,
        fontSize: '0.75rem', //12
        lineHeight: '16px',
        //grey.600
        color: '#778DA8'
    }
};
