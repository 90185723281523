const getInitials = (user) => {
    const name = `${user.firstName} ${user.secondName} `;
    return name
        .replace(/\s+/, ' ')
        .split(' ')
        .slice(0, 2)
        .map((v) => v && v[0].toUpperCase())
        .join('');
};

const getFullName = (user) => `${user?.lastName} ${user?.firstName} ${user?.secondName || ''}`;

const getFullNameWithInitials = (user) =>
    `${user.lastName} ${user.firstName.charAt(0)}.${
        user.secondName?.charAt(0) ? user.secondName?.charAt(0) + '.' : ''
    }`;

export {getInitials, getFullName, getFullNameWithInitials};
