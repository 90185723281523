import React, {useContext, useState} from 'react';
import {Box, FormControlLabel, Stack, Switch, Tabs, Typography} from '@mui/material';
import {TabContext} from '@mui/lab';
import NotificationTab from './components/NotificationTab';
import NotificationTabPanel from './components/NotificationTabPanel';
import {TARGET_TYPE_ENUM} from './constants';
import {AuthContext} from 'context/authContext';
import {RIGHTS_ENUM} from 'constants/rights';

const NotificationTabs = () => {
    const {hasPermission} = useContext(AuthContext);
    const [value, setValue] = useState(TARGET_TYPE_ENUM.SERVICE_REQUEST);
    const [onlyUnread, setOnlyUnread] = useState(false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeFilter = () => {
        setOnlyUnread(!onlyUnread);
    };

    return (
        <Box sx={{p: 0, minWidth: 450}}>
            <Stack justifyContent={'space-between'}>
                <Typography variant='h5' mx={2.5} my={1}>
                    Уведомления
                </Typography>
                <Stack spacing={1}>
                    <FormControlLabel
                        checked={onlyUnread}
                        onChange={handleChangeFilter}
                        control={<Switch />}
                        label='Только непрочитанные'
                    />
                </Stack>
            </Stack>
            <TabContext value={value}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    sx={{mx: 1.5, minHeight: 0, borderBottom: '0.5px solid', borderColor: 'grey.200'}}>
                    <NotificationTab value={TARGET_TYPE_ENUM.SERVICE_REQUEST} label={'Заявки'} />
                    {hasPermission(RIGHTS_ENUM.READ_PARTNER_NOTIFICATION_LIST) && (
                        <NotificationTab value={TARGET_TYPE_ENUM.PARTNER} label={'Партнёр'} />
                    )}
                    {hasPermission(RIGHTS_ENUM.READ_USER_NOTIFICATION_LIST) && (
                        <NotificationTab value={TARGET_TYPE_ENUM.USER} label={'Пользователь'} />
                    )}
                </Tabs>
                {value === TARGET_TYPE_ENUM.SERVICE_REQUEST && (
                    <NotificationTabPanel value={TARGET_TYPE_ENUM.SERVICE_REQUEST} onlyUnread={onlyUnread} />
                )}
                {value === TARGET_TYPE_ENUM.PARTNER && (
                    <NotificationTabPanel value={TARGET_TYPE_ENUM.PARTNER} onlyUnread={onlyUnread} />
                )}
                {value === TARGET_TYPE_ENUM.USER && (
                    <NotificationTabPanel value={TARGET_TYPE_ENUM.USER} onlyUnread={onlyUnread} />
                )}
            </TabContext>
        </Box>
    );
};

export default NotificationTabs;
