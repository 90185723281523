import {useQuery} from '@apollo/client';
import styled from '@emotion/styled';
import {Box, Tab, Typography} from '@mui/material';
import React, {useContext, useEffect} from 'react';
import {NotificationContext} from '../../NavBar/NotificationsMenu';
import {NOTIFICATION_TYPES} from 'constants/notifications';

const Counter = ({count}) => {
    return count ? (
        <Box sx={{backgroundColor: 'error.main', ml: 1, borderRadius: 16, px: 0.75, py: 0.1}}>
            <Typography fontWeight={500} color={'text.contrastText'} lineHeight={'16px'} fontSize={'12px'}>
                {count > 99 ? '99+' : count}
            </Typography>
        </Box>
    ) : null;
};

const NotificationTab = (props) => {
    const {data: notificationData, refetch} = useQuery(NOTIFICATION_TYPES[props.value]?.request);
    const notifications = notificationData
        ? notificationData[NOTIFICATION_TYPES[props.value]?.field]?.edges.map((i) => i.node)
        : [];
    const {subscriptionData} = useContext(NotificationContext);

    useEffect(() => {
        refetch();
    }, [subscriptionData]);

    return (
        <StyledTab
            icon={<Counter count={notifications.reduce((acc, curr) => (curr.isRead ? acc : acc + 1), 0)} />}
            iconPosition={'end'}
            {...props}
        />
    );
};

const StyledTab = styled(Tab)(({theme}) => ({
    minWidth: 0,
    minHeight: 0,
    padding: theme.spacing(1),
    opacity: 1
}));

export default NotificationTab;
