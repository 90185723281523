import {Typography} from '@mui/material';
import {CustomModal} from 'components/modals';
import React, {createContext, useState} from 'react';

export const ConfirmModalContext = createContext({});

export const ConfirmModalProvider = ({children}) => {
    const [open, setOpen] = useState(false);
    const [modalContent, setModalContent] = useState({
        title: '',
        text: '',
        buttonText: '',
        handleSubmit: async () => {},
        loading: null
    });
    const {title, text, buttonText, handleSubmit, loading} = modalContent;

    const handleOpenModal = ({title, text, buttonText, handleSubmit, loading}) => {
        setModalContent({title, text, buttonText, handleSubmit, loading});
        setOpen(true);
    };
    const handleCloseModal = () => setOpen(false);
    const onSubmit = () => {
        handleSubmit().then(() => {
            handleCloseModal();
        });
    };

    return (
        <ConfirmModalContext.Provider value={{handleOpenModal}}>
            {children}
            <CustomModal
                open={open}
                handleSubmit={onSubmit}
                handleClose={handleCloseModal}
                loading={loading}
                title={title}
                buttonText={buttonText}>
                <Typography>{text}</Typography>
            </CustomModal>
        </ConfirmModalContext.Provider>
    );
};
