import {gql} from '@apollo/client';

export const MARK_AS_READ = gql`
    mutation markAsRead($notificationId: UUID!) {
        markAsRead(notificationId: $notificationId) {
            id
            isRead
        }
    }
`;
