export default {
    defaultProps: {
        size: 'small'
    },
    styleOverrides: {
        root: ({theme}) => ({
            padding: 6,
            marginLeft: -8,
            color: theme.palette.divider,
            '&.Mui-disabled': {
                color: theme.palette.grey[400]
            }
        })
    }
};
