import {Stack, Typography} from '@mui/material';
import React from 'react';
import permissionDenied from 'assets/images/permission-denied.svg';
import ServicePageLayout from 'layouts/ServicePageLayout';

const Error404 = () => {
    return (
        <ServicePageLayout>
            <Stack direction={'column'} justifyContent='center' height='100%' textAlign={'center'}>
                <img src={permissionDenied} alt={'lock'} />
                <Typography variant={'h4'} mb={2}>
                    Страница не найдена
                </Typography>
                <Typography>Обратитесь в службу поддержки</Typography>
                <Typography sx={{textDecoration: 'underline'}}>
                    <a href='mailto:astral@astral.ru'>partners@astral.ru</a>
                </Typography>
            </Stack>
        </ServicePageLayout>
    );
};

export default Error404;
