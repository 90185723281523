import {Stack, Typography} from '@mui/material';
import React from 'react';
import internalError from 'assets/images/internal-error.svg';
import ServicePageLayout from 'layouts/ServicePageLayout';

const Error500 = ({error}) => {
    return (
        <ServicePageLayout>
            <Stack direction={'column'} justifyContent='center' height='100vh' textAlign={'center'}>
                <img src={internalError} alt={'lock'} />
                <Typography variant={'h4'} mb={2}>
                    Внутренняя ошибка
                </Typography>
                <Typography>{`Номер ошибки: ${error}`}</Typography>
                <Typography>Обратитесь в службу поддержки</Typography>
                <Typography sx={{textDecoration: 'underline'}}>
                    <a href='mailto:astral@astral.ru'>astral@astral.ru</a>
                </Typography>
            </Stack>
        </ServicePageLayout>
    );
};

export default Error500;
