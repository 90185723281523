export const PAGE_ENUM = {
    MANAGER: 'MANAGER',
    CI: 'CI',
    TSS: 'TSS',
    VENDOR: 'VENDOR',
    OTHER: 'OTHER'
};

export const CONVERSION_KEY = {
    MANAGER: 'MANAGER',
    CI: 'CI',
    TSS: 'TSS',
    VENDOR: 'OTHER'
};

export const REQUEST_LIST_NAME = {
    [PAGE_ENUM.MANAGER]: 'Заявки Партнёра',
    [PAGE_ENUM.CI]: 'Заявки ЦИ',
    [PAGE_ENUM.TSS]: 'Заявки СТП',
    [PAGE_ENUM.VENDOR]: 'Заявки Вендора'
};

export const REQUEST_NAME = {
    [PAGE_ENUM.MANAGER]: 'Партнёра',
    [PAGE_ENUM.CI]: 'ЦИ',
    [PAGE_ENUM.TSS]: 'СТП',
    [PAGE_ENUM.VENDOR]: 'Вендора'
};

export const REQUESTS_REPORT_FILENAME = {
    [PAGE_ENUM.MANAGER]: 'Реестр_заявок_менеджера',
    [PAGE_ENUM.CI]: 'Реестр_заявок_ЦИ',
    [PAGE_ENUM.TSS]: 'Реестр_заявок_СТП',
    [PAGE_ENUM.VENDOR]: 'Реестр_заявок_Вендора'
};

export const REQUESTS_STATISTICS_FILENAME = {
    [PAGE_ENUM.MANAGER]: 'Статистика_заявок_менеджера',
    [PAGE_ENUM.CI]: 'Статистика_заявок_ЦИ',
    [PAGE_ENUM.TSS]: 'Статистика_заявок_СТП',
    [PAGE_ENUM.VENDOR]: 'Статистика_заявок_Вендора'
};
