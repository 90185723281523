import {TARGET_TYPE_ENUM} from 'layouts/MainLayout/NotificationTabs/constants';
import {
    GET_ALL_PARTNER_NOTIFICATIONS,
    GET_ALL_REQUEST_NOTIFICATIONS,
    GET_ALL_USER_NOTIFICATIONS
} from 'graphql/notification/query';

export const NOTIFICATION_TYPES = {
    [TARGET_TYPE_ENUM.SERVICE_REQUEST]: {request: GET_ALL_REQUEST_NOTIFICATIONS, field: 'serviceRequestNotifications'},
    [TARGET_TYPE_ENUM.PARTNER]: {request: GET_ALL_PARTNER_NOTIFICATIONS, field: 'partnerNotifications'},
    [TARGET_TYPE_ENUM.USER]: {request: GET_ALL_USER_NOTIFICATIONS, field: 'userNotifications'}
};
